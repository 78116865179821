import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  navAuxOthersShow: false,
  className: "hide",
};

export const NavbarAuxSlice = createSlice({
  name: "navbarAux",
  initialState,
  reducers: {
    showProducts: (state, action) => {
      state.show = true;
      state.className = "show";
    },
    hideProducts: (state, action) => {
      state.show = false;
      state.className = "hide";
    },
    navbarAuxButtonShow: (state, action) => {
      state.navAuxOthersShow = true;
      state.className = "show";
    },
    navbarAuxButtonHide: (state, action) => {
      state.navAuxOthersShow = false;
      state.className = "hide";
    },
  },
});

export const { showProducts, hideProducts, navbarAuxButtonShow, navbarAuxButtonHide } =
  NavbarAuxSlice.actions;

export default NavbarAuxSlice.reducer;
