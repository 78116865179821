import React from "react";
import "./cardsV2.css";

const CardsV2 = () => {
  return (
    <>
      {/* <div className="content flow">
        <h1 className="title">Even columns</h1>
        <div className="even-columns">
          <div className="col">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt perspiciatis ipsam
            accusantium sit magni cumque nam iure sed ut similique quia, excepturi illum repudiandae
            obcaecati possimus officiis unde provident repellat reiciendis quaerat in! Quibusdam
            molestiae mollitia, ratione unde impedit veritatis! Sequi molestias, aliquam dicta
            veniam necessitatibus voluptas minus aspernatur et?
          </div>
          <div className="col">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat deleniti quidem
            deserunt, vitae quo alias dolore fugiat atque fuga sit doloribus amet molestias ab
            ratione!
          </div>
        </div>
      </div> */}
      <div className="content flow">
        <h1 className="title">Grid-ish</h1>
        <div className="grid-parent">
          <div className="grid-child">
            <div className="grid-ish2">
              <div className="col">
                <div className="blabla">
                  <ul>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                  </ul>
                </div>
              </div>{" "}
              <div className="col">
                <div className="blabla">
                  <ul>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                    <li>
                      <a href="/">Nume</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-child2">
            <div className="grid-ish">
              <div className="col">
                <div className="image">
                  <img
                    src={require("../../../Assets/images/panda.png")}
                    alt=""
                    width={"200px"}
                    height={"200px"}
                  />
                </div>
                <div className="description">
                  <span>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, necessitatibus!
                  </span>
                </div>
                <div className="stars">
                  <span>1</span>
                </div>
                <div className="price">
                  <span>2.0000 $</span>
                </div>
                <div className="stock">In stock</div>
                <div className="butonnes">
                  <button>Add to Cart</button>
                </div>
              </div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="content flow">
        <h1 className="title">Content and sidebar</h1>
        <div className="content-sidebar">
          <div className="col"></div>
          <div className="col"></div>
        </div>
      </div> */}
    </>
  );
};

export default CardsV2;
