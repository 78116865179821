import React from "react";
import "./navbarAux.css";
import MegaMenu from "../../UI/megaMenu/MegaMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarAuxProducts from "../navbar-aux-products/NavbarAuxProducts";
import { useDispatch, useSelector } from "react-redux";
import {
  showProducts,
  hideProducts,
  navbarAuxButtonShow,
  navbarAuxButtonHide,
} from "../../../features/navbarAux/navbarAuxSlice";

const NavbarAux = () => {
  const className = useSelector((state) => state.navBarAuxProducts.className);
  const showProd = useSelector((state) => state.navBarAuxProducts.show);
  const showList = useSelector((state) => state.navBarAuxProducts.navAuxOthersShow);
  console.log(className);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const locPath = location.pathname;
  const firstPath = location.pathname.split("/")[1];

  const handleClick = (e) => {
    e.preventDefault();

    navigation("/products");
  };

  const handleMouse = (e) => {
    e.preventDefault();
    if (!showProd) {
      dispatch(showProducts());
      // navigation("/products");
    } else {
      dispatch(hideProducts());
    }
  };
  const handleMouse2 = () => {
    dispatch(hideProducts());
  };

  const handleOthersClick = (e) => {
    e.preventDefault();
    dispatch(navbarAuxButtonShow());
  };
  const handleOthersClick2 = (e) => {
    e.preventDefault();
    dispatch(navbarAuxButtonHide());
  };
  console.log(showList);

  return (
    <>
      {locPath === "/" ? (
        <nav className="nav navbar-aux-outer">
          <div className="container">
            <div className="navbar-aux-container">
              <div className={`navbar-aux-body tick`}>
                <div className="navbar-aux-head">
                  <div
                    className={`navbar-aux-head-inner home`}
                    onMouseEnter={handleMouse}
                    onMouseLeave={handleMouse2}
                    onClick={handleClick}
                  >
                    <span>Products</span>
                  </div>
                  {showProd ? (
                    <div className={`navbar-aux-products-body home`}>
                      <NavbarAuxProducts />
                    </div>
                  ) : null}
                </div>
                <div className="navbar-aux-button-others">
                  <div
                    className="navbar-aux-button-others-inner"
                    onMouseEnter={handleOthersClick}
                    onMouseLeave={handleOthersClick2}
                  >
                    <span>Others</span>
                  </div>
                </div>
                <div className="navbar-box-inner">
                  <ul>
                    <li>
                      <Link to="#">Genius</Link>
                      <Link to="#">Genius Deals</Link>
                      <Link to="#">Resigilate</Link>
                      <Link to="#">Oferta Zilei</Link>
                      <Link to="#">Card</Link>
                      <Link to="#">Ofertele ALCRRO</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="navbar-box-footer">
                <ul>
                  <li>
                    <Link to="#">Help</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      ) : firstPath === "cart" ? (
        <nav className="nav navbar-aux-outer tick">
          <div className="container">
            <div className="navbar-aux-container">
              <div className="navbar-aux-body">
                <div className="navbar-aux-head tick">
                  <div className="navbar-aux-head-inner tick">
                    <span>Products</span>
                  </div>
                </div>

                <div className="navbar-box-inner">
                  <ul>
                    <li>
                      <Link to="#">Genius</Link>
                      <Link to="#">Genius Deals</Link>
                      <Link to="#">Resigilate</Link>
                      <Link to="#">Oferta Zilei</Link>
                      <Link to="#">Card</Link>
                      <Link to="#">Ofertele ALCRRO</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="navbar-box-footer">
                <ul>
                  <li>
                    <Link to="#">Help</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      ) : (
        <nav className="nav navbar-aux-outer">
          <div className="container">
            <div className="navbar-aux-container">
              <div className="navbar-aux-body">
                <div className="navbar-aux-head tick">
                  <div
                    className={`navbar-aux-head-inner tick ${className}`}
                    onMouseEnter={handleMouse}
                    onMouseLeave={handleMouse2}
                    onClick={handleClick}
                  >
                    <span>Products</span>
                  </div>
                  {showProd ? (
                    <div className={`navbar-aux-products-body products`}>
                      <NavbarAuxProducts />
                    </div>
                  ) : null}
                </div>
                <div className="navbar-aux-button-others">
                  <div
                    className="navbar-aux-button-others-inner"
                    onMouseEnter={handleOthersClick}
                    onMouseLeave={handleOthersClick2}
                  >
                    <span>Others</span>
                  </div>
                </div>
                {showList ? (
                  <div className="navbar-box-inner">
                    <ul>
                      <li>
                        <Link to="#">Genius</Link>
                        <Link to="#">Genius Deals</Link>
                        <Link to="#">Resigilate</Link>
                        <Link to="#">Oferta Zilei</Link>
                        <Link to="#">Card</Link>
                        <Link to="#">Ofertele ALCRRO</Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="navbar-box-inner">
                    <ul>
                      <li>
                        <Link to="#">Genius</Link>
                        <Link to="#">Genius Deals</Link>
                        <Link to="#">Resigilate</Link>
                        <Link to="#">Oferta Zilei</Link>
                        <Link to="#">Card</Link>
                        <Link to="#">Ofertele ALCRRO</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="navbar-box-footer">
                <ul>
                  <li>
                    <Link to="#">Help</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default NavbarAux;
