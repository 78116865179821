import axios from "axios";
import authHeader from "../auth/authHeader";
import { API_URL } from "../../configs";

// Get product by id
const getProductById = async (id) => {
  const response = await axios.get(API_URL + `product/${id}`);
  return response.data;
};

// Create a product
const createProduct = async (product) => {
  const response = await axios.post(API_URL + "admin/", product, { headers: authHeader() });
  // console.log(response);
  return response.data;
};

const postProductService = {
  getProductById,
  createProduct,
};

export default postProductService;
