import React from "react";
import Exercises from "./50CodingChallanges/Exercise";
import CardsV2 from "../../Components/products/cards/CardsV2";

const Home = () => {
  return (
    <div>
      <h2>Home</h2>
      <div className="filters">
        <div className="brand"></div>
        <Exercises />
      </div>
      <div className="cards-v2">
        <CardsV2 />
      </div>
    </div>
  );
};

export default Home;
